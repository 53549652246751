import React, {useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import { Helmet } from "react-helmet";
const prohlaseni = "/assets/PROHLASENI OBJEDNATELE.docx"
const plnamoc = "/assets/PLNA MOC.docx"
// import Link from "gatsby-link"

// import { useInView } from "react-intersection-observer";
// import anime from "animejs"
import * as css from "./index.module.scss";

const addScript = url => {
   const script = document.createElement("script")
   script.src = url
   script.async = true
   document.body.appendChild(script)
}

const IndexPage = ({ location }) => {
   useEffect(() =>{
      addScript("https://booking.reservanto.cz/Script/reservanto-script.js?id=15987")
   }, [])   
  const siteUrl = location.origin ? location.origin : "";

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "akcie.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 598, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  // * seo settings
  let meta = [];
  meta.push({ name: "og:image", content: data.file.childImageSharp.fluid.src });
  meta.push({
    name: "twitter:image",
    content: siteUrl + data.file.childImageSharp.fluid.src,
  });
  // if (!!seo && !!seo.title) {
  //   meta.push({ property: `og:title`, content: seo.title });
  //   meta.push({ property: `tw:title`, content: seo.title });
  // }
  // if (!!seo && !!seo.description) {
  //   meta.push({ name: `description`, content: seo.description });
  //   meta.push({ property: `og:description`, content: seo.description });
  //   meta.push({ property: `tw:description`, content: seo.description });
  // }
  // if (!!image && !!image.publicURL) {
  //   meta.push({ name: "og:image", content: image.publicURL });
  //   meta.push({ name: "twitter:image", content: image.publicURL });
  // }
  // if (!!seo && !!seo.keywords) {
  //   meta.push({ property: `keywords`, content: seo.keywords });
  // }
  return (
    <>
      <Helmet meta={meta} />
      <div className={css.wrapper}>
        {/* <h1>        
          Nadace
          <br /> Svoboda slova
          <br /> a myšlení
        </h1>
        <div className={css.center}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Voucher nadace Svoboda slova"
            className={css.image}
          />
        </div>
        <p>
          <strong>
            Ve spolupráci s vydavatelství Echo Media Vám děkujeme, že jste se
            stali předplatiteli Echo Komplet a tímto podpořili myšlenku svobody
            slova.
          </strong> Zároveň s předplatným jste získali možnost stát se vlastníkem
          kusové akcie společnosti Echo Media a.s. a tím i akcionářem tohoto
          nezávislého vydavatelství.
        </p> */}        
        
        <h2>Děkujeme!</h2>
        <p>Koupí Echo Komplet jste podpořili tvorbu nezávislého mediálního obsahu. K němu Vám Nadace Svoboda slova a myšlení dává možnost získat akcii Echo Media a.s.</p>
        <h2>Dostali jste voucher. Jak ho směníte za akcii?</h2>
        <p>
          Po úhradě předplatného jste  obdrželi e-mail s odkazem
          na voucher, který můžete do 31.3.2022 vyměnit za jeden kus kusové akcie na jméno v listinné podobě v sídle společnosti Echo Media a.s. na adrese <a href="https://mapy.cz/s/labenojuke" target="_blank" rel="noopener noreferrer nofollow"><strong>Malostranské náměstí 203/14, Malá Strana, Praha 1, 118 00.</strong></a>
        </p>
        <iframe src="https://frame.mapy.cz/s/pazobumora" width="800" height="200" frameBorder="0"></iframe>        
        <p>Akcii ze zákona nelze zasílat poštou. Pokud se nemůžete dostavit akcii vyzvednout osobně, stačí vyplnit Plnou moc, kterou si můžete stáhnout <a href={plnamoc} download="Plná moc.docx"><strong>ZDE</strong></a> a akcii za Vás s tímto dokumentem může vyzvednout Vámi určená osoba.</p>
        <h2>Chcete akcii někomu věnovat?</h2>
        <p>Pokud jste si předplatné objednali, avšak chcete, aby se akcionářem stal někdo jiný, vyplňte Prohlášení objednatele, které si můžete stáhnout <a href={prohlaseni} download="Prohlášení objednatele.docx"><strong>ZDE</strong></a>. Tento dokument spolu s voucherem musí mít Vámi určená osoba s sebou.</p>
        <h2>Kdy si můžete akcii vyzvednout?</h2>
        <p><strong>Vydávání akcií bude zahájeno 15. prosince 2020.</strong> Stačí si rezervovat termín, kdy si akcii budete moci vyzvednout.</p>
       
        <div className={css.flaotingButtonWrapper}>
          <a className={css.button} href="https://booking.reservanto.cz/Modal/?id=15987" target="_blank" rel="noopener noreferrer nofollow">Rezervovat termín převzetí akcie</a>
        </div>
        {/* <div className="reservanto-widget" data-text="Rezervovat termín převzetí akcie" data-id="15987" data-color-text="#ffffff" data-color-text-shadow="#2b6cc0" data-color-bg="#399cd1" data-color-bg-hover="#2aabeb" data-color-boxshadow="#2282b5" ></div> */}
         {/* <script id="reservanto-widget-script" type="text/javascript" src="https://booking.reservanto.cz/Script/reservanto-script.js?id=15987"></script> */}

         <h2>Co s sebou k převzetí akcie</h2>         
         <ol>
            <li>Platný občanský průkaz nebo jiný rovnocenný doklad totožnosti u fyzické osoby, v případě právnické osoby výpis z obchodního rejstříku nebo jiné obdobné evidence obsahující potřebné právní náležitosti u právnické osoby, ne starší než 3 měsíce.</li>
            <li>Referenční číslo objednávky předplatného.</li>
            <li>Voucher na akcii. </li>
            <li>Číslo bankovního účtu</li>
            <li>Případně Plnou moc nebo Prohlášení objednatele.</li>            
         </ol>
      </div>
    </>
  );
};

export default IndexPage;
